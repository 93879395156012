import React, { useState } from 'react'
import { Policy } from 'graphqlTypes'
import { useDisclosure } from '@chakra-ui/hooks'
import { Collapse } from '@chakra-ui/transition'
import { Box, Divider, Text, useColorModeValue as mode } from '@chakra-ui/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
interface PolicyProps {
  data: Policy
  index: number
}

const PolicyCard: React.FC<PolicyProps> = ({ data, index }) => {
  const { isOpen, onToggle } = useDisclosure()
  const [countOpen, setCountOpen] = useState(0)
  if (index === 0 && countOpen === 0) {
    onToggle()
    setCountOpen(1)
  }
  return (
    <Box>
      {index !== 0 && <Divider my="7" borderColor="#3C3D35" />}
      <Text
        onClick={onToggle}
        color={isOpen ? 'brand.500' : mode('#3C3D35', 'gray.400')}
        fontWeight="extrabold"
        align="left"
        fontSize="lg"
        maxW="md"
        mb="5"
      >
        {data.title}
      </Text>
      <Collapse in={isOpen} animateOpacity>
        <Box color={mode('#3C3D35', 'gray.400')}>
          <MDXRenderer>{data.content.childMdx.body}</MDXRenderer>
        </Box>
      </Collapse>
    </Box>
  )
}

export default PolicyCard
