import React from 'react'
import {
  Box,
  Heading,
  useColorModeValue as mode,
  Text,
  SimpleGrid,
} from '@chakra-ui/react'
import Layout from 'components/Layout'
import Seo from 'components/Seo'
import { Policy } from 'graphqlTypes'
import { graphql, PageProps } from 'gatsby'
import PolicyCard from 'components/PolicyCard'
import MarkDownProvider from 'components/MarkDownProvider'

interface Data {
  allContentfulPolicies: {
    edges: Array<{
      node: Policy
    }>
  }
}
const PolicyPage: React.FC<PageProps<Data>> = ({ data }) => {
  return (
    <Layout isFullWidth>
      <Seo title="Privacy Policy" />
      <Box as="section" py="16" bg={mode('gray.50', 'gray.800')}>
        <Box
          maxW={{ base: 'xl', md: '7xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
          align="center"
        >
          <Heading
            align="center"
            fontWeight="extrabold"
            maxW="md"
            mx="auto"
            color={mode('gray.800', 'gray.100')}
          >
            Privacy Policy
          </Heading>
          <Text fontSize="lg" mt="6" color={mode('gray.600', 'gray.400')}>
            We value your privacy.
          </Text>
        </Box>
      </Box>
      <Box
        as="section"
        bg={mode('#FFFFFF', 'gray.800')}
        py={{ base: '5', sm: '5' }}
        minH="30rem"
      >
        <Box
          maxW={{ base: 'xl', md: '5xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
        >
          <MarkDownProvider>
            <SimpleGrid>
              {data &&
                data.allContentfulPolicies.edges.map((blog, index) => (
                  <PolicyCard data={blog.node} key={index} index={index} />
                ))}
            </SimpleGrid>
          </MarkDownProvider>
        </Box>
      </Box>
      <Box as="section" py="16" bg={mode('brand.500', 'brand.500')}>
        <Box
          maxW={{ base: 'xl', md: '7xl' }}
          mx="auto"
          px={{ base: '6', md: '8' }}
          align="center"
        >
          <Heading
            align="center"
            fontWeight="extrabold"
            mx="auto"
            color={mode('#FFFFFF', 'gray.100')}
          >
            Can’t find what you’re looking for?
          </Heading>
          <Text fontSize="lg" mt="6" color={mode('#FFFFFF', 'gray.400')}>
            Contact us support@walawong.com
          </Text>
        </Box>
      </Box>
    </Layout>
  )
}

export default PolicyPage

export const pageQuery = graphql`
  query GetAllPolicy {
    allContentfulPolicies(sort: { fields: order, order: ASC }) {
      edges {
        node {
          title
          content {
            childMdx {
              body
            }
          }
          order
        }
      }
    }
  }
`
